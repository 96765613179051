import React, { useEffect, useRef, useState } from "react";

const A2rp = (props) => {
    const a2rpRef = useRef(null);
    const mainText = "an Ashish Ranjan presentation";
    const [countDelay, setCountDelay] = useState(0);

    const [text, setText] = useState("");
    const [count, setCount] = useState(0);
    const [direction, setDirection] = useState("forward");
    const [returnPoint, setReturnPoint] = useState(0);
    const [timeDelay, setTimeDelay] = useState(1000 * (1 / 30));

    useEffect(() => {
        const timeout = setTimeout(() => {
            // console.log(count, "count");
            setText(mainText.substring(0, count));
            if (count === returnPoint) {
                setCountDelay(countDelay + 1);
                if (countDelay > 20) {
                    setCountDelay(0);
                    setCount(count - 1);
                    setDirection("backward");
                    if (returnPoint === 0) { setReturnPoint(2); }
                    if (returnPoint === 2) { setReturnPoint(9); }
                    if (returnPoint === 9) { setReturnPoint(16); }
                    if (returnPoint === 16) { setReturnPoint(29); }
                    if (returnPoint === 29) { setReturnPoint(0); }
                }
                return;
            }

            if (direction === "forward") {
                setCount(count + 1);
            } else if (direction === "backward") {
                setCount(count - 1);
            }
            if (count >= mainText.length) {
                setDirection("backward");
            } else if (count <= 0) {
                setDirection("forward");
            }
        }, timeDelay);

        return () => { clearTimeout(timeout); };
    }, [count, countDelay]);

    return (
        <div title="a2rp: an Ashish Ranjan presentation">
            <span style={{ color: props.a2rpColor, fontSize: props.fontSize }}>a2rp:</span> <span
                ref={a2rpRef}
                style={{
                    color: "orangered",
                    fontSize: props.animationFontSize || "10px"
                }}
            >
                {text}<span style={{ color: "#00f", fontSize: "12px" }}>|</span>
            </span>
        </div>
    )
}

export default A2rp
