import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa6";
import HamburgerNavLinks from "./hamburgerNavLinks";
import Swal from "sweetalert2";
import axios from "axios";

const Header = () => {
    const navigate = useNavigate();
    const line1Ref = useRef(null);
    const line2Ref = useRef(null);
    const line3Ref = useRef(null);
    const row2Ref = useRef(null);
    const [hamburgerEnabled, setHamburgerEnabled] = useState(false);

    useEffect(() => {
        const line1 = line1Ref.current;
        const line2 = line2Ref.current;
        const line3 = line3Ref.current;
        const row2 = row2Ref.current;
        if (hamburgerEnabled === true) {
            line1.style.cssText = `transform: translateY(-13.5px) translateX(8px) rotateZ(45deg); background-color: red;`;
            line2.style.cssText = `transform: rotateX(90deg); background-color: red;`;
            line3.style.cssText = `transform: translateY(13.5px) translateX(8px) rotateZ(-45deg); background-color: red;`;
            row2.style.cssText = `left: 0;`;
        } else if (hamburgerEnabled === false) {
            line1.style.cssText = `transform: translateY(-10px)`;
            line2.style.cssText = `transform: rotateX(0)`;
            line3.style.cssText = `transform: translateY(10px)`;
            row2.style.cssText = `left: 100%;`;
        }
    }, [hamburgerEnabled]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                if (hamburgerEnabled === true) {
                    setHamburgerEnabled(false);
                }
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [hamburgerEnabled]);

    const handleLogout = () => {
        Swal.fire({
            title: "Do you want to logout?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem("FBIdToken");
                delete axios.defaults.headers.common["Authorization"];
                navigate("/user/login");
            }
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.row1}>
                <div className={styles.leftSection}>
                    <NavLink to="/home" className={`${styles.leftSection}`}>
                        <div className={styles.name}>Ashish Ranjan</div>
                        <div className={styles.logo}>...ash - remember the name</div>
                    </NavLink>
                </div>
                <div className={styles.rightSection}>
                    <NavLink to="/home" className={`${styles.navLink} ${styles.homeNavLink}`}>
                        <div className={styles.navLinkText}>Home</div>
                        <div className={styles.underline}></div>
                    </NavLink>
                    <div className={styles.navLink}>
                        <div className={styles.navLinkText}>About</div>
                        <FaCaretDown className={styles.caretDown} />
                        <div className={styles.aboutNavLinksContainer}>
                            <NavLink className={styles.aboutNavLink} to="/about/resume">Resume</NavLink>
                            <NavLink className={styles.aboutNavLink} to="/about/contact">Contact</NavLink>
                            <NavLink className={styles.aboutNavLink} to="/about/contact">Hire me</NavLink>
                        </div>
                    </div>
                    <NavLink to="/notes" className={`${styles.navLink} ${styles.notesNavLink}`}>
                        <div className={styles.navLinkText}>Notes</div>
                        <div className={styles.underline}></div>
                    </NavLink>
                    <div className={styles.navLink}>
                        <div className={styles.navLinkText}>Projects</div>
                        <FaCaretDown className={styles.caretDown} />
                        <div className={styles.projectsNavLinksContainer}>
                            <NavLink className={styles.projectsNavLink} to="/projects/list">Projects list</NavLink>

                            <NavLink className={styles.projectsNavLink} to="/projects/movie-search-app">Movie search app</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/random-quotes-generator">Random quotes generator</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/memes-generator">Memes generator</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/paint">Paint</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/dictionary">Dictionary</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/avataar-generator">Avataar generator</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/movie-trailer">Movie trailer</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/lyrics-finder">Lyrics finder</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/weather">Weather app.</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/scroll-to">Scroll to</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/tic-tac-toe">Tic tac toe with baby AI</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/search-autocomplete">Search auto complete</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/github-profile-finder">Github profile finder</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/modal-popups">Custom modal popups</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/dynamic-tabs">Dynamic tabs</NavLink>
                            {/* <NavLink className={styles.projectsNavLink} to="/projects/scroll-progress-indicator">Scroll progress indicator</NavLink> */}
                            <NavLink className={styles.projectsNavLink} to="/projects/toggle-theme">Toggle theme</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/qr-code">QR code</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/image-slider">Image slider</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/star-rating">Star rating</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/accordion">Accordion</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/task-scheduler">Task scheduler</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/html-colors">HTML colors</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/language-translator">Language translator</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/validators">Validators</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/quiz-application">Quiz application</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/todo-app">Todo app</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/rock-paper-scissor">Rock paper scissor</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/password-generator">Password generator</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/jokes-generator">Jokes generator</NavLink>
                            <NavLink className={styles.projectsNavLink} to="/projects/mui-form">Material UI Form</NavLink>
                        </div>
                    </div>
                    <div className={styles.navLink}>
                        <div className={styles.navLinkText}>User</div>
                        <FaCaretDown className={styles.caretDown} />
                        <div className={styles.userNavLinksContainer}>
                            <NavLink className={styles.userNavLink} to="/user/account">Control Panel</NavLink>
                            <NavLink className={styles.userNavLink} to="/user/register">Register</NavLink>
                            <NavLink className={styles.userNavLink} to="/user/login">Login</NavLink>
                            <NavLink className={styles.userNavLink} to="/user/reset-password">Reset password</NavLink>
                            <div className={styles.userNavLink} onClick={handleLogout}>Logout</div>
                        </div>
                    </div>
                    <div className={styles.hamburger} onClick={() => setHamburgerEnabled(hamburgerEnabled => !hamburgerEnabled)}>
                        <div ref={line1Ref} className={styles.line}></div>
                        <div ref={line2Ref} className={styles.line}></div>
                        <div ref={line3Ref} className={styles.line}></div>
                    </div>
                </div>
            </div>
            <div ref={row2Ref} className={styles.row2}>
                <div className={styles.emptySection} onClick={() => setHamburgerEnabled(hamburgerEnabled => !hamburgerEnabled)}></div>
                <div className={styles.navLinksContainer}><HamburgerNavLinks /></div>
            </div>
        </div>
    )
}

export default Header

