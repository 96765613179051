import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const WebsiteVisits = () => {
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [visitorsCount, setVisitorsCount] = useState(window.localStorage.getItem("visitorsCount") || 0);

    const getVisitorCount = async () => {
        try {
            const config = {
                method: "GET",
                url: "/visitors",
            };
            setIsLoading(true);
            const response = await axios(config);
            // console.log(response, "visitors get count");
            const data = response.data;
            window.localStorage.setItem("visitorsCount", data.count);
        } catch (error) {
            // console.log(error, "error count get");
        } finally {
            setIsLoading(false);
            setVisitorsCount(window.localStorage.visitorsCount || 0);
        }
    };
    useEffect(() => {
        // console.log(cookies, "cookies");
        getVisitorCount();
    }, [pathname, visitorsCount]);

    useEffect(() => {
        const updateVisitorCount = async () => {
            try {
                const config = {
                    method: "PATCH",
                    url: "/visitors",
                    withCredentials: true,
                };
                const updateResponse = await axios(config);
            } catch (error) {
                // console.log(error, "error");
            }
        };
        updateVisitorCount();
    }, []);

    return (
        <></>
    )
}

export default WebsiteVisits

