import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const HamburgerNavLinks = () => {
    const [searchInput, setSearchInput] = useState("");
    const [aboutHeight, setAboutHeight] = useState(0);
    const [notesHeight, setNotesHeight] = useState(0);
    const [projectsHeight, setProjectsHeight] = useState(0);
    const [userHeight, setuserHeight] = useState(0);

    const displayNavLinks = (containerName) => {
        if (containerName === "about") {
            if (aboutHeight === 0) {
                setAboutHeight("auto");
            } else {
                setAboutHeight(0);
            }
        } else if (containerName === "notes") {
            if (notesHeight === 0) {
                setNotesHeight("auto");
            } else {
                setNotesHeight(0);
            }
        } else if (containerName === "projects") {
            if (projectsHeight === 0) {
                setProjectsHeight("auto");
            } else {
                setProjectsHeight(0);
            }
        } else if (containerName === "user") {
            if (userHeight === 0) {
                setuserHeight("auto");
            } else {
                setuserHeight(0);
            }
        }
    };

    useEffect(() => {
        // console.log(searchInput, "before");
        // console.log(searchInput, "after");
        setAboutHeight("auto");
        setNotesHeight("auto");
        setProjectsHeight("auto");
        setuserHeight("auto");
        const navlinks = document.querySelectorAll(".navlink");
        navlinks.forEach(element => {
            const elementsClassName = element.className;
            // console.log(elementsClassName);
            if (!elementsClassName.toLowerCase().includes(searchInput)) {
                element.style.cssText = `display: none;`;
            } else {
                // console.log(element, "searched");
                element.style.cssText = `display: flex; height: 40px;`;
                // console.log("found", elementsClassName);
            }
        });
    }, [searchInput]);

    return (
        <div className={styles.container}>
            <div className={styles.searchInputContainer}>
                <input
                    className={styles.searchInput}
                    type="text"
                    placeholder="Search here"
                    value={searchInput}
                    onChange={event => setSearchInput(event.target.value)}
                />
            </div>
            <div className={styles.navLinksContainer}>
                <NavLink to="/home" className={`${styles.homeNavLink} navlink home`}>
                    <ArrowRightIcon className={styles.homeNavLinkIcon} />
                    <div className={styles.homeNavLinkText}>Home</div>
                </NavLink>

                <div className={`${styles.aboutNavLink} navlink about resume contact hire me`}
                    onClick={() => displayNavLinks("about")}>
                    <ArrowRightIcon className={styles.aboutNavLinkIcon} />
                    <div className={styles.aboutNavLinkText}>About</div>
                </div>
                <div className={styles.aboutNavLinksContainer}
                    style={{ height: aboutHeight }}>
                    <NavLink to="/about/resume" className={`${styles.notesNavLink} navlink about resume`}><ArrowRightIcon />Resume</NavLink>
                    <NavLink to="/about/contact" className={`${styles.notesNavLink} navlink about contact`}><ArrowRightIcon />Contact</NavLink>
                    <NavLink to="/about/contact" className={`${styles.notesNavLink} navlink about contact hire me`}><ArrowRightIcon />Hire me</NavLink>
                </div>

                <NavLink to="/notes" className={`${styles.notesNavLink} navlink notes`}>
                    <ArrowRightIcon className={styles.notesNavLinkIcon} />
                    <div className={styles.notesNavLinkText}>Notes</div>
                </NavLink>

                <div
                    className={`${styles.projectsNavLink} navlink projects list all password generator jokes generator material mui form todo app quiz application validators language translator html colors task scheduler accordion star rating image slider qr code toggle theme scroll progress indicator dynamic tabs github profile finder search autocomplete tic tac toe with baby ai scroll to weather app. lyrics finder movie trailer avataar generator dictionary paint memes generator random quotes generator movie search app`}
                    onClick={() => displayNavLinks("projects")}>
                    <ArrowRightIcon className={styles.projectsNavLinkIcon} />
                    <div className={styles.projectsNavLinkText}>Projects</div>
                </div>
                <div className={styles.projectsNavLinksContainer} style={{ height: projectsHeight }}>
                    <NavLink to="/projects/list" className={`${styles.projectsNavLink} navlink projects list all`}>
                        <ArrowRightIcon /> Projects list
                    </NavLink>

                    <NavLink to="/projects/movie-search-app" className={`${styles.projectsNavLink} navlink projects movie search app`}>
                        <ArrowRightIcon /> Movie search app
                    </NavLink>
                    <NavLink to="/projects/random-quotes-generator" className={`${styles.projectsNavLink} navlink projects random quotes generator`}>
                        <ArrowRightIcon /> Random quotes generator
                    </NavLink>
                    <NavLink to="/projects/memes-generator" className={`${styles.projectsNavLink} navlink projects memes generator`}>
                        <ArrowRightIcon /> Memes generator
                    </NavLink>
                    <NavLink to="/projects/paint" className={`${styles.projectsNavLink} navlink projects paint`}>
                        <ArrowRightIcon /> Paint
                    </NavLink>
                    <NavLink to="/projects/dictionary" className={`${styles.projectsNavLink} navlink projects dictionary`}>
                        <ArrowRightIcon /> Dictionary
                    </NavLink>
                    <NavLink to="/projects/avataar-generator" className={`${styles.projectsNavLink} navlink projects avataar genertor`}>
                        <ArrowRightIcon /> Avataar genertor
                    </NavLink>
                    <NavLink to="/projects/movie-trailer" className={`${styles.projectsNavLink} navlink projects movie trailer`}>
                        <ArrowRightIcon /> Movie trailer
                    </NavLink>
                    <NavLink to="/projects/lyrics-finder" className={`${styles.projectsNavLink} navlink projects lyrics finder`}>
                        <ArrowRightIcon /> Lyrics finder
                    </NavLink>
                    <NavLink to="/projects/weather" className={`${styles.projectsNavLink} navlink projects weather`}>
                        <ArrowRightIcon /> Weather app.
                    </NavLink>
                    <NavLink to="/projects/scroll-to" className={`${styles.projectsNavLink} navlink projects scroll to`}>
                        <ArrowRightIcon /> Scroll to
                    </NavLink>
                    <NavLink to="/projects/tic-tac-toe" className={`${styles.projectsNavLink} navlink projects tic tac toe with baby ai`}>
                        <ArrowRightIcon /> Tic tac toe with baby AI
                    </NavLink>
                    <NavLink to="/projects/search-autocomplete" className={`${styles.projectsNavLink} navlink projects search autocomplete`}>
                        <ArrowRightIcon /> Search autocomplete
                    </NavLink>
                    <NavLink to="/projects/github-profile-finder" className={`${styles.projectsNavLink} navlink projects github profile finder`}>
                        <ArrowRightIcon /> Github profile finder
                    </NavLink>
                    <NavLink to="/projects/modal-popups" className={`${styles.projectsNavLink} navlink projects modal pop ups`}>
                        <ArrowRightIcon /> Modal pop ups
                    </NavLink>
                    <NavLink to="/projects/dynamic-tabs" className={`${styles.projectsNavLink} navlink projects dynamic tabs`}>
                        <ArrowRightIcon /> Dynamic tabs
                    </NavLink>
                    {/* <NavLink to="/projects/scroll-progress-indicator" className={`${styles.projectsNavLink} navlink projects scroll progress indicator`}>
                        <ArrowRightIcon /> Scroll progress indicator
                    </NavLink> */}
                    <NavLink to="/projects/toggle-theme" className={`${styles.projectsNavLink} navlink projects toggle theme`}>
                        <ArrowRightIcon /> Toggle theme
                    </NavLink>
                    <NavLink to="/projects/qr-code" className={`${styles.projectsNavLink} navlink projects qr code`}>
                        <ArrowRightIcon /> QR code
                    </NavLink>
                    <NavLink to="/projects/image-slider" className={`${styles.projectsNavLink} navlink projects image slider`}>
                        <ArrowRightIcon /> Image slider
                    </NavLink>
                    <NavLink to="/projects/star-rating" className={`${styles.projectsNavLink} navlink projects star rating`}>
                        <ArrowRightIcon /> Star rating
                    </NavLink>
                    <NavLink to="/projects/accordion" className={`${styles.projectsNavLink} navlink projects accordion`}>
                        <ArrowRightIcon /> Accordion
                    </NavLink>
                    <NavLink to="/projects/task-scheduler" className={`${styles.projectsNavLink} navlink projects task scheduler`}>
                        <ArrowRightIcon /> Task scheduler
                    </NavLink>
                    <NavLink to="/projects/html-colors" className={`${styles.projectsNavLink} navlink projects html colors`}>
                        <ArrowRightIcon /> HTML colors
                    </NavLink>
                    <NavLink to="/projects/language-translator" className={`${styles.projectsNavLink} navlink projects language translator`}>
                        <ArrowRightIcon /> Language translator
                    </NavLink>
                    <NavLink to="/projects/validators" className={`${styles.projectsNavLink} navlink projects validators`}>
                        <ArrowRightIcon /> Validators
                    </NavLink>
                    <NavLink to="/projects/quiz-application" className={`${styles.projectsNavLink} navlink projects quiz application`}>
                        <ArrowRightIcon /> Quiz application
                    </NavLink>
                    <NavLink to="/projects/todo-app" className={`${styles.projectsNavLink} navlink projects todo app`}>
                        <ArrowRightIcon /> Todo app
                    </NavLink>
                    <NavLink to="/projects/rock-paper-scissor" className={`${styles.projectsNavLink} navlink projects rock paper scissor`}>
                        <ArrowRightIcon /> Rock Paper Scissor
                    </NavLink>
                    <NavLink to="/projects/password-generator" className={`${styles.projectsNavLink} navlink projects password generator`}>
                        <ArrowRightIcon /> Password generator
                    </NavLink>
                    <NavLink to="/projects/jokes-generator" className={`${styles.projectsNavLink} navlink projects jokes generator`}>
                        <ArrowRightIcon /> Jokes generator
                    </NavLink>
                    <NavLink to="/projects/mui-form" className={`${styles.projectsNavLink} navlink projects material mui form`}>
                        <ArrowRightIcon /> Material UI Form
                    </NavLink>
                </div>

                <div className={`${styles.userNavLink} navlink user control panel register login reset password`} onClick={() => displayNavLinks("user")}>
                    <ArrowRightIcon className={styles.userNavLinkIcon} />
                    <div className={styles.userNavLinkText}>user</div>
                </div>
                <div className={styles.userNavLinksContainer} style={{ height: userHeight }}>
                    <NavLink to="/user/account" className={`${styles.notesNavLink} navlink user control panel`}>
                        <ArrowRightIcon />Control Panel
                    </NavLink>
                    <NavLink to="/user/register" className={`${styles.notesNavLink} navlink user register`}>
                        <ArrowRightIcon />Register
                    </NavLink>
                    <NavLink to="/user/login" className={`${styles.notesNavLink} navlink user login`}>
                        <ArrowRightIcon />Login
                    </NavLink>
                    <NavLink to="/user/reset-password" className={`${styles.notesNavLink} navlink user reset password`}>
                        <ArrowRightIcon />Reset password
                    </NavLink>
                </div>

                <NavLink to="/cookies-policy" className={`${styles.cookiesPolicyNavLink} navlink cookies policy`}>
                    <ArrowRightIcon className={styles.cookiesPolicyNavLinkIcon} />
                    <div className={styles.cookiesPolicyNavLinkText}>Cookies Policy</div>
                </NavLink>

                <NavLink to="/disclaimer" className={`${styles.disclaimerNavLink} navlink disclaimer`}>
                    <ArrowRightIcon className={styles.disclaimerNavLinkIcon} />
                    <div className={styles.disclaimerNavLinkText}>Disclaimer</div>
                </NavLink>

                <NavLink to="/faq" className={`${styles.faqNavLink} navlink faq`}>
                    <ArrowRightIcon className={styles.faqNavLinkIcon} />
                    <div className={styles.faqNavLinkText}>FAQ</div>
                </NavLink>

                <NavLink to="/privacy-policy" className={`${styles.privacyPolicyNavLink} navlink privacy policy`}>
                    <ArrowRightIcon className={styles.privacyPolicyNavLinkIcon} />
                    <div className={styles.privacyPolicyNavLinkText}>Privacy Policy</div>
                </NavLink>

                <NavLink to="/return-policy" className={`${styles.returnPolicyNavLink} navlink return policy`}>
                    <ArrowRightIcon className={styles.returnPolicyNavLinkIcon} />
                    <div className={styles.returnPolicyNavLinkText}>Return Policy</div>
                </NavLink>

                <NavLink to="/terms-and-conditions" className={`${styles.termsAndConditionsNavLink} navlink terms and conditions`}>
                    <ArrowRightIcon className={styles.termsAndConditionsNavLinkIcon} />
                    <div className={styles.termsAndConditionsNavLinkText}>Terms and Conditions</div>
                </NavLink>

                <NavLink to="/contribute" className={`${styles.contributeNavLink} navlink contribute`}>
                    <ArrowRightIcon className={styles.contributeNavLinkIcon} />
                    <div className={styles.contributeNavLinkText}>Contribute</div>
                </NavLink>
            </div>
        </div>
    )
}

export default HamburgerNavLinks

