import { CircularProgress } from '@mui/material'
import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const wait = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
}

const About = lazy(() => wait(500).then(() => import("../pages/about")));
const Contact = lazy(() => wait(500).then(() => import("../pages/about/contact")));
const HireMe = lazy(() => wait(500).then(() => import("../pages/about/hireMe")));
const Resume = lazy(() => wait(500).then(() => import("../pages/about/resume")));

const Home = lazy(() => wait(500).then(() => import("../pages/home")));
const Notes = lazy(() => wait(500).then(() => import("../pages/notes")));

const Projects = lazy(() => wait(500).then(() => import("../pages/projects")));
const ProjectsList = lazy(() => wait(500).then(() => import("../pages/projects/projectsList")));

const MovieSearchApp = lazy(() => wait(500).then(() => import("../pages/projects/movieSearchApp")));
const RandomQuotesGenerator = lazy(() => wait(500).then(() => import("../pages/projects/randomQuotes")));
const MemesGenerator = lazy(() => wait(500).then(() => import("../pages/projects/memesGenerator")));
const PaintApp = lazy(() => wait(500).then(() => import("../pages/projects/paintApp")));
const Dictionary = lazy(() => wait(500).then(() => import("../pages/projects/dictionary")));
const Avataar = lazy(() => wait(500).then(() => import("../pages/projects/avataar")));
const MovieTrailer = lazy(() => wait(500).then(() => import("../pages/projects/movieTrailer")));
const LyricsFinder = lazy(() => wait(500).then(() => import("../pages/projects/lyricsFinder")));
const WeatherApp = lazy(() => wait(500).then(() => import("../pages/projects/weatherApp")));
const ScrollTo = lazy(() => wait(500).then(() => import("../pages/projects/scrollTo")));
const TicTacToe = lazy(() => wait(500).then(() => import("../pages/projects/ticTacToe")));
const SearchAutoComplete = lazy(() => wait(500).then(() => import("../pages/projects/searchAutoComplete")));
const GithubProfileFinder = lazy(() => wait(500).then(() => import("../pages/projects/githubProfileFinder")));
const ModalComp = lazy(() => wait(500).then(() => import("../pages/projects/modalComp")));
const ToggleTheme = lazy(() => wait(500).then(() => import("../pages/projects/toggleTheme")));
const DynamicTabs = lazy(() => wait(500).then(() => import("../pages/projects/dynamicTabs")));
const ScrollProgressIndicator = lazy(() => wait(500).then(() => import("../pages/projects/scrollProgressIndicator")));
const QrCode = lazy(() => wait(500).then(() => import("../pages/projects/qrCode")));
const ImageSlider = lazy(() => wait(500).then(() => import("../pages/projects/imageSlider")));
const StarRating = lazy(() => wait(500).then(() => import("../pages/projects/starRating")));
const Accordion = lazy(() => wait(500).then(() => import("../pages/projects/accordion")));
const TaskScheduler = lazy(() => wait(500).then(() => import("../pages/projects/taskScheduler")));
const HtmlColors = lazy(() => wait(500).then(() => import("../pages/projects/htmlColors")));
const LanguageTranslator = lazy(() => wait(500).then(() => import("../pages/projects/languageTranslator")));
const Validators = lazy(() => wait(500).then(() => import("../pages/projects/validators")));
const QuizApplication = lazy(() => wait(500).then(() => import("../pages/projects/quizApplication")));
const TodoApp = lazy(() => wait(500).then(() => import("../pages/projects/todoApp")));
const RockPaperScissor = lazy(() => wait(500).then(() => import("../pages/projects/rockPaperScissor")));
const PasswordGenerator = lazy(() => wait(500).then(() => import("../pages/projects/passwordGenerator")));
const JokesGenerator = lazy(() => wait(500).then(() => import("../pages/projects/jokesGenerator")));
const MuiForm = lazy(() => wait(500).then(() => import("../pages/projects/muiForm")));

const User = lazy(() => wait(500).then(() => import("../pages/user")));
const Login = lazy(() => wait(500).then(() => import("../pages/user/login")));
const Register = lazy(() => wait(500).then(() => import("../pages/user/register")));
const ResetPassword = lazy(() => wait(500).then(() => import("../pages/user/resetPassword")));
const Account = lazy(() => wait(500).then(() => import("../pages/user/account")));

const CookiesPolicy = lazy(() => wait(500).then(() => import("../pages/other/cookiesPolicy")));
const Disclaimer = lazy(() => wait(500).then(() => import("../pages/other/disclaimer")));
const Faq = lazy(() => wait(500).then(() => import("../pages/other/faq")));
const PrivacyPolicy = lazy(() => wait(500).then(() => import("../pages/other/privacyPolicy")));
const ReturnPolicy = lazy(() => wait(500).then(() => import("../pages/other/returnPolicy")));
const TermsAndConditions = lazy(() => wait(500).then(() => import("../pages/other/termsAndConditions")));

const PageNotFound = lazy(() => wait(500).then(() => import("../pages/pageNotFound")));

const Router = () => {
    return (
        <Suspense fallback={<CircularProgress sx={{ marginTop: "50px", marginLeft: "50%" }} />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />}>
                    <Route path="contact" element={<Contact />} />
                    <Route path="hire-me" element={<HireMe />} />
                    <Route path="resume" element={<Resume />} />
                </Route>

                <Route path="/notes" element={<Notes />} />
                <Route path="/projects" element={<Projects />}>
                    <Route path="list" element={<ProjectsList />} />

                    <Route path="movie-search-app" element={<MovieSearchApp />} />
                    <Route path="random-quotes-generator" element={<RandomQuotesGenerator />} />
                    <Route path="memes-generator" element={<MemesGenerator />} />
                    <Route path="paint" element={<PaintApp />} />
                    <Route path="dictionary" element={<Dictionary />} />
                    <Route path="avataar-generator" element={<Avataar />} />
                    <Route path="movie-trailer" element={<MovieTrailer />} />
                    <Route path="lyrics-finder" element={<LyricsFinder />} />
                    <Route path="weather" element={<WeatherApp />} />
                    <Route path="scroll-to" element={<ScrollTo />} />
                    <Route path="tic-tac-toe" element={<TicTacToe />} />
                    <Route path="search-autocomplete" element={<SearchAutoComplete />} />
                    <Route path="github-profile-finder" element={<GithubProfileFinder />} />
                    <Route path="modal-popups" element={<ModalComp />} />
                    <Route path="dynamic-tabs" element={<DynamicTabs />} />
                    <Route path="scroll-progress-indicator" element={<ScrollProgressIndicator />} />
                    <Route path="toggle-theme" element={<ToggleTheme />} />
                    <Route path="qr-code" element={<QrCode />} />
                    <Route path="image-slider" element={<ImageSlider />} />
                    <Route path="star-rating" element={<StarRating />} />
                    <Route path="accordion" element={<Accordion />} />
                    <Route path="task-scheduler" element={<TaskScheduler />} />
                    <Route path="html-colors" element={<HtmlColors />} />
                    <Route path="language-translator" element={<LanguageTranslator />} />
                    <Route path="validators" element={<Validators />} />
                    <Route path="quiz-application" element={<QuizApplication />} />
                    <Route path="todo-app" element={<TodoApp />} />
                    <Route path="rock-paper-scissor" element={<RockPaperScissor />} />
                    <Route path="password-generator" element={<PasswordGenerator />} />
                    <Route path="jokes-generator" element={<JokesGenerator />} />
                    <Route path="mui-form" element={<MuiForm />} />
                </Route>

                {/* <Route path="/user" element={<User />} /> */}
                <Route path="/user" element={<User />}>
                    <Route path="account" element={<Account />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                </Route>

                <Route path="cookies-policy" element={<CookiesPolicy />} />
                <Route path="disclaimer" element={<Disclaimer />} />
                <Route path="faq" element={<Faq />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="return-policy" element={<ReturnPolicy />} />
                <Route path="terms-and-conditions" element={<TermsAndConditions />} />

                <Route path="*" element={<PageNotFound />} />
            </Routes>

        </Suspense >
    )
}

export default Router

