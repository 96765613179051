import React, { useEffect, useRef } from "react"
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import Router from "./router";
import Header from "./components/header";
import Footer from "./components/footer";
import axios from "axios";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WebsiteVisits from "./components/websiteVisits/WebsiteVisits";

const App = () => {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        axios.defaults.baseURL = "http://localhost:5000/ashishranjan-net/asia-south1/api";
    } else {
        axios.defaults.baseURL = "https://asia-south1-ashishranjan-net.cloudfunctions.net/api";
    }

    // axios.defaults.baseURL = "http://localhost:5000/ashishranjan-net/asia-south1/api";
    // axios.defaults.baseURL = "https://asia-south1-ashishranjan-net.cloudfunctions.net/api";

    const mainRef = useRef(null);
    const { pathname } = useLocation();
    useEffect(() => {
        mainRef.current.scrollTo(0, 0);
    }, [pathname]);

    const goTopFunc = () => {
        mainRef.current.scrollTo(0, 0);
    }

    useEffect(() => {
        const myButton = document.querySelector(".arrowUp");
        const main = mainRef.current;
        const handleScroll = event => {
            if (main.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                myButton.style.display = "block";
            } else {
                myButton.style.display = "none";
            }
        };

        main.addEventListener("scroll", handleScroll);

        return () => {
            main.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.header}><Header /></div>
            <div ref={mainRef} className={styles.main}>
                <div className={styles.router}><Router /></div>
                <div className={styles.footer}><Footer /></div>
            </div>

            <WebsiteVisits />
            <ArrowCircleUpIcon className={`${styles.arrowUp} arrowUp`} onClick={goTopFunc} />
            <ToastContainer />
        </div>
    )
}

export default App

