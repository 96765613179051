import React from "react";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import iosAppStore from "./images/ios-app-store.png";
import googlePlayStore from "./images/google-play-store.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import { FaFacebookF } from "react-icons/fa";

import YouTubeIcon from "@mui/icons-material/YouTube";
import { CiYoutube } from "react-icons/ci";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { FaLinkedinIn } from "react-icons/fa";

import GitHubIcon from "@mui/icons-material/GitHub";
import { FiGithub } from "react-icons/fi";

import A2rp from "../a2rp";
import { IconButton, Tooltip } from "@mui/material";

const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <div className={styles.row1}>
                    <div className={styles.section1}>
                        <div className={styles.col1}>
                            <div className={styles.logoContainer}>
                                <div className={styles.logoAnimationDiv}>
                                    <div className={styles.logoMainDiv}></div>
                                    <div className={styles.logoText}>AR</div>
                                </div>
                                <div className={styles.logoNameDialog}>
                                    <div className={styles.name}>Ashish Ranjan</div>
                                    <div className={styles.dialog}>...ash - remember the name</div>
                                </div>
                            </div>

                            <div className={styles.mobileStoreContainer}>
                                <img src={iosAppStore} alt="" />
                                <img src={googlePlayStore} alt="" />
                            </div>
                        </div>
                        <div className={styles.col2}>
                            <NavLink to="/home" className={styles.navlink}>Home</NavLink>
                            <NavLink to="/about/resume" className={styles.navlink}>Resume</NavLink>
                            <NavLink to="/about/contact" className={styles.navlink}>Contact</NavLink>
                            <NavLink to="/notes" className={styles.navlink}>Notes</NavLink>
                            <NavLink to="/projects/list" className={styles.navlink}>Projects</NavLink>
                            <NavLink to="/user/register" className={styles.navlink}>Register</NavLink>
                            <NavLink to="/user/login" className={styles.navlink}>Login</NavLink>
                        </div>
                    </div>
                    <div className={styles.section2}>
                        <div className={styles.col3}>
                            <NavLink to="/cookies-policy" className={`${styles.navlink} ${styles.cookiesPolicy}`}>Cookies policy</NavLink>
                            <NavLink to="/disclaimer" className={`${styles.navlink} ${styles.disclaimer}`}>Disclaimer</NavLink>
                            <NavLink to="/faq" className={`${styles.navlink} ${styles.faq}`}>FAQ</NavLink>
                            <NavLink to="/privacy-policy" className={`${styles.navlink} ${styles.privacyPolicy}`}>Privacy policy</NavLink>
                            <NavLink to="/return-policy" className={`${styles.navlink} ${styles.returnPolicy}`}>Return policy</NavLink>
                            <NavLink to="/terms-and-conditions" className={`${styles.navlink} ${styles.termsAndConditions}`}>Terms and conditions</NavLink>
                            <NavLink to="/contribute" className={`${styles.navlink} ${styles.termsAndConditions}`}>Contribute</NavLink>
                        </div>
                        <div className={styles.col4}>
                            <div className={styles.address}>
                                Seegehalli, Krishnarajapura<br />
                                Bangalore - 560049<br />
                                Karnataka, The Republic of India
                            </div>
                            <div className={styles.socialLinksContainer}>
                                <a
                                    className={`${styles.link} ${styles.facebookLink}`}
                                    href="https://www.facebook.com/theash.ashish/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaFacebookF className={styles.socialIcon} />
                                </a>
                                <a
                                    className={`${styles.link} ${styles.youtubeLink}`}
                                    href="https://www.youtube.com/channel/UCLHIBQeFQIxmRveVAjLvlbQ"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <CiYoutube className={styles.socialIcon} />
                                </a>
                                <a
                                    className={`${styles.link} ${styles.linkedinLink}`}
                                    href="https://www.linkedin.com/in/aashishranjan/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaLinkedinIn className={styles.socialIcon} />
                                </a>
                                <a
                                    className={`${styles.link} ${styles.githubLink}`}
                                    href="https://github.com/a2rp"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FiGithub className={styles.socialIcon} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.row2}>
                    <div className={styles.col1}>
                        <A2rp fontSize={10} />
                    </div>
                    <div className={styles.col2}>
                        Copyright &copy; {new Date().getFullYear()} - All rights reserved
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer
